import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  LeshenPhoneCTA,
  LinkButton,
  PackageCard,
  useMapi,
  useUser,
} from '@leshen/gatsby-theme-leshen'
import {
  Columns,
  scrollTo,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/ui'
import { Link } from 'gatsby'
import React, { useEffect } from 'react'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import getUrlParameter from '../utils/getUrlParameter'
import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

import theme from '../../theme'

const ZipPackageSection = ({ hsi, hsiLogo }) => {
  const { userData, userLocation, setUserData, setZipLocation } = useUser()
  const { rotatedNumber } = useMapi()

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData.loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if aff_unique3 exists in the url. (if hsi template)
    // that param is something that consumer may send to us and if so, we want to run the
    // zip logic on that zipcode (if userData doesn't already exist)
    let zipcode

    if (hsi) {
      zipcode = getUrlParameter('aff_unique3') || userLocation?.zipCode
    } else {
      zipcode = userLocation?.zipCode
    }

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (zipcode && zipcode.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(zipcode, 'internet')

        setZipLocation(zipcode)

        setUserData({
          loading: false,
          showModal: false,
          highspeedCenturylinkPackages: packageData,
        })
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [
    userData,
    setUserData,
    availablePlans,
    setAvailablePlans,
    userLocation,
    setZipLocation,
    hsi,
  ])

  /**
   * This should be handled in the callback for the availability
   * lookup, but because that is handled through shadowing, and not
   * through passing down props from here, it is not feasible. I
   * decided it would be best to have the scrollTo and it's target
   * in the same file after considering all this.
   */
  useEffect(() => {
    if (availablePlans?.highspeedCenturylinkPackages?.packages?.length >= 1) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Searching packages in your area.
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {availablePlans?.highspeedCenturylinkPackages?.packages?.length >= 1 &&
        !arePackagesLoading && (
          <VariableContent
            className="packages"
            alignMainContent="center"
            mainContent={
              <div>
                {hsi && hsiLogo && (
                  <>
                    <Typography variant="h2">
                      CenturyLink<sup>®</sup> is available in your area
                    </Typography>
                    <Typography variant="h4">
                      With no annual contracts and no data caps.
                    </Typography>
                  </>
                )}

                {!hsi && (
                  <Typography variant="h2">
                    Affordable internet with speeds for everyday tasks
                  </Typography>
                )}

                {userLocation.city && userLocation.zipCode && (
                  <Typography>
                    <strong>
                      Showing best plan for {userLocation.city},{' '}
                      {/* eslint-disable-next-line */}
                      {userLocation.zipCode}{' '}
                      {hsi && (
                        <StyledButtonLink href="/availability">
                          change
                        </StyledButtonLink>
                      )}
                    </strong>
                  </Typography>
                )}
              </div>
            }
          >
            <Stack spacing="xxl">
              <Columns mobileCarousel>
                {availablePlans.highspeedCenturylinkPackages.packages.map(
                  (data) => (
                    <PackageCard
                      label={data.label}
                      packageData={{
                        ...data.brandy,
                      }}
                      hsi={hsi}
                      content={
                        <>
                          {hsi && (
                            <LinkButton
                              to="/cart"
                              CustomLink={Link}
                              color="primary"
                            >
                              Order Online
                            </LinkButton>
                          )}
                          {/* Disabled lint line due to Gatsby api named
                          variable */}
                          {/* eslint-disable-next-line no-underscore-dangle */}
                          <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
                        </>
                      }
                      key={data.label}
                    />
                  )
                )}
              </Columns>
            </Stack>
          </VariableContent>
        )}

      {availablePlans?.highspeedCenturylinkPackages?.packages?.length === 0 &&
        !arePackagesLoading && (
          <VariableContent
            alignMainContent="center"
            mainContent={
              <div>
                {userLocation.city && userLocation.zipCode && (
                  <Typography variant="h2">
                    No plans found in {userLocation.city}
                  </Typography>
                )}
                <StyledButtonsContainer>
                  Call&nbsp;
                  <Link href={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>
                  &nbsp; now for other options in your area
                </StyledButtonsContainer>
              </div>
            }
          />
        )}

      {/**
       * If userData is not null, and its `loading` property is set to false
       * by availabilitySubmit, then a fetch request has just completed.
       * That in combination with having no beam package data should mean
       * that there are no packages for the location the user searched.
       */}
      {availablePlans &&
        !availablePlans.loading &&
        availablePlans?.highspeedCenturylinkPackages?.packages === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  {`We're having trouble locating service options for your area.`}
                </Typography>
                <p>
                  Give us a call and one of our Internet specialists can help
                  get you connected!
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

export default ZipPackageSection

const StyledButtonLink = styled.a(
  () => css`
    color: ${theme.colors.primary};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: inline;
    font-weight: bold;
    text-decoration: none;
  `
)

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .view-plans-button {
    margin-right: 16px;
  }
`
